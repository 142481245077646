import React from "react"
import moment from 'moment'
import PropTypes from "prop-types"
import { Table, Typography, DatePicker, Space, Button, Tag } from 'antd';
import { Link } from "react-router-dom";
import { SearchOutlined, FilterFilled, LinkOutlined } from "@ant-design/icons";
import CurrencyFormat from "react-currency-format";

const { Paragraph } = Typography

const statusColorMappings = {
  'purchased': 'green',
  'refunded': 'gray',
  'adjusted': 'gray',
  'failed': 'red',
}

class List extends React.Component {
  state = {
    data: this.props.contacts,
    pagination: {
      current: 1,
      showSizeChanger: true,
      total: this.props.totalConversions,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    }
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Space direction="vertical">
          <DatePicker.RangePicker
            ref={node => {
              this.searchInput = node;
            }}
            onChange={e => setSelectedKeys(e !== null ? [e[0].format("DD/MM/YYYY"), e[1].format("DD/MM/YYYY")] : [])}
            placeholder={`Search ${dataIndex}`}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8 }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </Space>
      </div>
    ),
    filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />,
  });

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
    });
    this.props.onTableChange(pagination, filters, sorter);
  };

  render() {
    const { loading, conversions, availableProducts } = this.props;

    console.log(conversions)
    const productFilters = availableProducts.map((product) => ({
      text: product.name,
      value: product.slug,
    }))
    const statusFilters = [
      {
        text: 'purchased',
        value: 'success',
      },
      {
        text: 'failed',
        value: 'failed',
      },
      {
        text: 'refunded',
        value: 'refunded',
      },
      {
        text: 'adjusted',
        value: 'adjusted',
      },      
    ]

    let columns = [
      {
        title: 'Transaction ID',
        dataIndex: 'transaction_id',
        render: (txn_id, record) => record.id,
      },
      {
        title: 'Product',
        dataIndex: 'link_product',
        render: (product, record) => record.product.name,
        filterMultiple: true,
        filters: productFilters,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status) => (
          <Tag color={statusColorMappings[status]} key={status}>
            {status}
          </Tag>
        ),
        filterMultiple: true,
        filters: statusFilters,
      },
      {
        title: 'Revenue',
        dataIndex: 'revenue',
        render: (revenue) => <CurrencyFormat value={revenue} displayType='text' prefix={'$'} decimalScale={2} fixedDecimalScale={revenue % 1 !== 0} thousandSeparator />
      },
      {
        title: 'Commission',
        dataIndex: 'commission',
        render: (commission) => <CurrencyFormat value={commission} displayType='text' prefix={'$'} decimalScale={2} fixedDecimalScale={commission % 1 !== 0} thousandSeparator />,
        sorter: true,
      },
      {
        title: 'Subscription ID',
        dataIndex: 'subscription_id',
        render: (subscription_id) => subscription_id,
      },
      {
        title: 'uInvoice ID',
        dataIndex: 'invoice_id',
        render: (invoice, record) => record.invoice.id,
        sorter: true,
      },
      {
        title: 'Created Date',
        dataIndex: 'created_at',
        render: (created_at) => moment(created_at).format('MMM DD, YYYY'),
        sorter: true,
      },
      {
        title: 'Refund Date',
        dataIndex: 'refund_date',
        render: (invoice, record) => (record.invoice.refund_date ? 
          moment(record.invoice.refund_date).format('MMM DD, YYYY') : 
          <Tag/>
        ),
        sorter: true,
      },
      {
        title: 'Payment Method',
        dataIndex: 'payment_method',
        render: (invoice, record) => (record.invoice.payment_method ? 
          record.invoice.payment_method : 
          <Tag/>
        ),
      },
      {
        title: 'Link',
        dataIndex: 'link',
        render: (link) => (
          <Paragraph style={{ marginBottom: 0 }} copyable={{ text: link }}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <LinkOutlined style={{ fontSize: "16px", color: "#08c" }} />
            </a>
          </Paragraph>
        ),
        sorter: true,
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
    ];

    const { pagination } = this.state;
    pagination.total = this.props.totalConversions;
    pagination.current = this.props.page;

    return (
      <Table
        columns={columns}
        rowKey={record => record.id}
        dataSource={conversions}
        pagination={pagination}
        loading={loading}
        onChange={this.handleTableChange}
        size="middle"
        scroll={{ x: true }}
      />
    );
  }
}

List.propTypes = {
  totalConversions: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  conversions: PropTypes.array.isRequired,
  availableProducts: PropTypes.array.isRequired,
  onTableChange: PropTypes.func.isRequired,
};

List.defaultProps = {
  totalConversions: 0,
  page: 0,
  loading: true,
  conversions: [],
  availableProducts: [],
};

export default List;
